import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style5">
        <div className="inner">
            <header className="major">
                <h1>入会のご案内</h1>
            </header>
            <div className="content">
                <p>当法人への入会についてのご案内をいたします。
                <br />
                ご不明な点がございましたら、遠慮なくお問い合わせください。</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
