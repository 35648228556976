import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerNyukaidetail from '../components/BannerNyukaidetail'
import { StaticImage } from "gatsby-plugin-image"

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Nyukaidetail = (props) => (
    <Layout>
        <Helmet>
            <title>入会のご案内</title>
            <meta name="description" content="入会のご案内" />
        </Helmet>


        <BannerNyukaidetail
     />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>会員種別</h2>
                    </header>
                    <p>当法人の会員種別および提供サービス等についてご紹介します。<br/>ご不明な点がございましたら、お問い合わせください。
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <StaticImage src="../images/intmeeting.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>自治体特別会員</h3>
                            </header>
                            <h4>適用団体：地方公共団体等行政機関またはその一部署</h4>
                            <h5>会費：無料</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、可視化活用ノウハウの提供等</h5>
                            <h5>会員の責務：都市構造可視化の活用事例を所定の様式にてご提供ください。（年１回程度）</h5>
                            <p>
                            </p>
                        </div>
                    </div>
                </section>
                
                <section>
                    <div className="image">
                        <StaticImage src="../images/ovsmeeting.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>自治体会員</h3>
                            </header>
                            <h4>適用団体：地方公共団体等行政機関またはその一部署</h4>
                            <h5>会費：15万円/年</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、可視化活用ノウハウの提供、可視化に必要な機材の貸し出し（ノートパソコン、wifiルータ（4G通信量無制限）、3Dマウス）、オリジナルkml作成の支援</h5>
                            <h5>会員の責務：都市構造可視化の活用事例を所定の様式にてご提供ください。（年１回程度）</h5>
                        </div>
                    </div>
                </section>
{/* 
                <section>
                    <Link to="" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>教育特別会員</h3>
                            </header>
                            <h4>適用団体：教育機関またはその一部署</h4>
                            <h5>会費：無料</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、可視化活用ノウハウの提供等</h5>
                            <h5>会員の責務：都市構造可視化の活用事例を所定の様式にてご提供ください。（年１回程度）</h5>
                            <p>
                            </p>
                        </div>
                    </div>
                </section>
                
                <section>
                    <Link to="" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>教育会員</h3>
                            </header>
                            <h4>適用団体：教育機関またはその一部署</h4>
                            <h5>会費：15万円/年</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、可視化活用ノウハウの提供、可視化に必要な機材の貸し出し（ノートパソコン、wifiルータ（4G通信量無制限）、3Dマウス）、オリジナルkml作成の支援</h5>
                            <h5>会員の責務：都市構造可視化の活用事例を所定の様式にてご提供ください。（年１回程度）</h5>
                        </div>
                    </div>
                </section> */}

                <section>
                    <div className="image">
                        <StaticImage src="../images/intmembers.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>賛助会員</h3>
                            </header>
                            <h4>適用団体：活動の趣旨に賛同する法人</h4>
                            <h5>会費：15万円/年</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、情報提供、可視化活用ノウハウの提供、可視化ロゴの利用、法人ロゴのサイトへの掲載および法人サイトへのリンク</h5>
                            <h5>会員の責務：状況に応じて、各種事業におけるご協力をお願いいたします。</h5>
                            <p>なお、入会にあたり所定の審査がございます。</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <StaticImage src="../images/kensyu.jpg" alt="imgae" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>上記以外の方へ</h3>
                            </header>
                            <h4>会員制度はニーズに応じて随時改善しております</h4>
                            <h5>お気軽にお問い合わせください。</h5>
                        </div>
                    </div>
                </section>
                {/* <section>
                    <div className="image">
                    <img src={pic09} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>個人会員</h3>
                            </header>
                            <h4>適用団体：趣旨に賛同する個人</h4>
                            <h5>会費：3万円/年（日本都市計画学会会員は無料）</h5>
                            <h5>提供サービス：都市構造可視化の活用方法に関する質問受付、可視化活用ノウハウの提供</h5>
                            <h5>会員の責務：各種事業へのご協力をお願いいたします。</h5>
                        </div>
                    </div>
                </section> */}

                <section id="one1">
                <div className="inner">
                    <header className="major">
                <br/>
                    <h2>　入会手続きについて</h2>
                    </header>
                    <p>　お申し込みは、随時受け付けております。<br/>　入会にあたりご不明な点がございましたら、遠慮なくお問い合わせください。
                    </p>
                </div>
                </section>

            </section>
        </div>

    </Layout>
)

export default Nyukaidetail